import * as THREE from 'three';

export default class Scene {
    constructor() {}

    async start(rawData) {
        // await navigator.clipboard.writeText(JSON.stringify(rawData));

        // alert("success");
        // return;
        // rawData = newData;

        // rawData = JSON.parse('[{"altitude":364.3118843574769,"latitude":51.09748356588331,"longitude":-77.75940980084755},{"altitude":298.3092850900323,"latitude":78.35862368927002,"longitude":-107.16494595572094},{"altitude":64.67110736528923,"latitude":90.24561313817347,"longitude":-103.12922576145282},{"altitude":46.94207916183963,"latitude":76.74981051946406,"longitude":-61.97387761088855},{"altitude":58.10989183255827,"latitude":80.09881212211431,"longitude":-80.63616971576583},{"altitude":131.22301387152984,"latitude":72.19273784401287,"longitude":-109.79155701293726},{"altitude":245.48438523938546,"latitude":75.4025966553096,"longitude":-23.787628409388503},{"altitude":292.1552913161513,"latitude":100.55728611615572,"longitude":-31.80318927180457},{"altitude":173.5509774859668,"latitude":97.69073643484606,"longitude":-101.14566296797125},{"altitude":337.97647844734297,"latitude":91.49521412532044,"longitude":-31.434390511612364},{"altitude":53.88318767465013,"latitude":57.24458909481716,"longitude":-83.68613870055417},{"altitude":36.24964983387079,"latitude":133.58869899553548,"longitude":-51.45496700846567},{"altitude":289.5508235233947,"latitude":75.96129491161082,"longitude":-90.33061015609823},{"altitude":29.163104203673157,"latitude":102.11913648949408,"longitude":-45.041654499996056},{"altitude":329.5339733626415,"latitude":128.85786467104901,"longitude":-52.508578317816955},{"altitude":141.0105619806127,"latitude":61.65740204385585,"longitude":-81.30723591947402}]');

        if (!this.renderer) {
            this.scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
            camera.position.set( 0 , 400, 400 );
            camera.lookAt( 0, 0, 0 );
    
            this.camera = camera;
            const renderer = new THREE.WebGLRenderer({
                preserveDrawingBuffer: true
            });
            renderer.setSize( window.innerWidth, window.innerHeight );
            document.body.appendChild( renderer.domElement );
            this.renderer = renderer;

            window.renderer = renderer;
        }

        const latitude = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
        const longitude = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
        
        rawData.forEach((data) => {
            latitude[0] = Math.min(latitude[0], data.latitude)
            latitude[1] = Math.max(latitude[1], data.latitude)
            longitude[0] = Math.min(longitude[0], data.longitude)
            longitude[1] = Math.max(longitude[1], data.longitude)
        });

        this.rawData = rawData;

        const points = rawData.map((data) => {
            const latitudeValue = latitude[1] === latitude[0] ? 300 : (data.latitude - latitude[0]) / (latitude[1] - latitude[0]) * 300;
            const longitudeValue = longitude[1] === longitude[0] ? 300 : (data.longitude - longitude[0]) / (longitude[1] - longitude[0]) * 300;
            return new THREE.Vector3( longitudeValue, latitudeValue, data.altitude ) 
        });

        this.points = points;

        const geometry = new THREE.BufferGeometry().setFromPoints( points );
        const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
        const line = new THREE.Line( geometry, material );
        this.scene.add( line );

        this.line = line;

        this.line.geometry.computeBoundingBox();
        const center = new THREE.Vector3();
        this.line.geometry.boundingBox.getCenter(center);
        
        this.line.geometry.center();
        
        // line.translateX(-150);
        // line.translateY(-150);

        const dotGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3( 0, 0, 0)]);
        const dotMaterial = new THREE.PointsMaterial( { size: 5, sizeAttenuation: false, color: 0x888888 } );
        this.dot = new THREE.Points( dotGeometry, dotMaterial );
        this.scene.add( this.dot );
        // console.log("perf", -1 * center.x)

        // this.dot.translateX(-1 * center.x);
        // this.dot.translateY(-1 * center.y);

        this.center = center;


        this.count = 0;
        this.dotIndex = 0;
        this.animate();
    }

    animate = () => {
        // this.line.rotation.x += 0.01;
        // this.line.rotation.y += 0.01;
        const frames = 300;
        let q = this.q

        if (!q) {
            this.q = new THREE.Quaternion();
            this.q.setFromAxisAngle( new THREE.Vector3(0, 0, 1), 1 * Math.PI * 2 / frames );
            q = this.q;
        }


        // const rotWorldMatrix = new THREE.Matrix4();
        // rotWorldMatrix.makeRotationAxis(new THREE.Vector3(0, 0, 1).normalize(), 1 * Math.PI * 2 / frames);
        // rotWorldMatrix.multiply(this.line.matrix);        // pre-multiply
        // this.line.matrix = rotWorldMatrix;
        // this.line.rotation.setFromRotationMatrix(this.line.matrix);


        // this.line.quaternion.multiplyQuaternions( q, this.line.quaternion );

        this.line.rotateOnAxis(new THREE.Vector3(0, 0, 1), 1 * Math.PI * 2 / frames)

        // this.line.quaternion.multiply(q);
        // this.line.position.applyQuaternion( q );

        // this.line.rotateZ(1 * Math.PI * 2 / frames)

        // this.line.rotation.z += 0.02;


        // let q2 = this.line.quaternion.clone()
        // q2.multiplyQuaternions(q, q2);
        // this.line.position.applyQuaternion( q2 );
        
        // this.count++;

        
        

        // if (this.count === 10) {
            this.dotIndex++;
        //     this.count = 0;

            this.dotIndex = this.dotIndex % this.points.length;
            const vector = this.points[this.dotIndex];
            // console.log("perf", vector)
            this.dot.position.set(vector.x, vector.y, vector.z);

        this.dot.translateX(-1 * this.center.x);
        this.dot.translateY(-1 * this.center.y);


            // this.dot.quaternion.multiplyQuaternions( q, this.dot.quaternion );

            // console.log("perf", this.dot.quaternion);

            // this.dot.quaternion.multiply(this.line.quaternion)
            this.dot.position.applyQuaternion( this.line.quaternion );

            this.q = q;

            const rawData = this.rawData[this.dotIndex]
            document.querySelector('.altitude .value').textContent = `${rawData.altitude.toFixed(2)} m`;

            
        // console.log("perf", this.line.geometry.attributes.position.array[0], this.line.geometry.attributes.position.array[1], this.line.geometry.attributes.position.array[2])
        // }

        this.renderer.render( this.scene, this.camera );
        this.rafId = requestAnimationFrame( this.animate );
    }

    dispose() {
        cancelAnimationFrame(this.rafId);
        this.line.removeFromParent();
        this.dot.removeFromParent();
    }
}