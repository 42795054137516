import logo from './logo.svg';
import './App.css';
import THREE from 'three';

import * as React from 'react';
import Controller from './controller';

const controller = new Controller();
let t = false;

function App() {
  const [tracing, updateTracing] = React.useState(false);

  const handleTracing = () => {

    if (tracing) {
      updateTracing(false);
      controller.stop();
    } else {
      updateTracing(true);
      controller.start();
    }
  }

  // React.useEffect(() => {
  //   if (!t) {
  //     controller.stop();
  //     t= true;
  //   }

  // }, [])

  const example = () => {
    controller.example();
  }

  const share = async () => {



    const shareData = {
      title: "Geo path",
      text: "Geo path",
      url: "https://geo-path.pages.dev/",
    };

    // const input = document.getElementById("files");
    // const files = input.files;
    // console.log("perf", files)

    // shareData.files = files;
    
    // if (window.renderer) {
    //   const dataUrl = window.renderer.domElement.toDataURL();
    //   // console.log("perf", dataUrl);

    //   const base64Response = await fetch(dataUrl);
    //   const blob = await base64Response.blob();

    //   shareData.files = [
    //     new File([blob], 'file.png', {
    //       type: blob.type,
    //     })
    //   ];
    // }

    await navigator.share(shareData);
  }

  return (
    <div className="App">
      <div className="toolbar">
        <button onClick={handleTracing}>{tracing ? 'Stop tracing' : 'Start tracing'}</button>
        <button onClick={share}>Share</button>
        <button onClick={example}>Example</button>
        {/* <input type="file" id="files" /> */}
      </div>
      <div className="altitude">Altitude: <span className="value"></span></div>
    </div>
  );
}

export default App;
